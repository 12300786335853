import React from 'react'
import Helmet from 'react-helmet';
import ReactMarkdown from 'react-markdown'

import Layout from '@/components/Layout'
import SEO from '@/helpers/SEOHelper.js';


const TermsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <Helmet>
        <meta name='description' content={SEO.description(frontmatter.seo.description)} />
        <meta property='og:title' content={SEO.title(frontmatter.seo.title)} />
        <meta property='og:description' content={SEO.description(frontmatter.seo.description)} />
        <meta property='og:image' content={SEO.image('null')} />
        <title>{SEO.title(frontmatter.seo.title)}</title>
      </Helmet>
      <section>
        <div className='container-sm mt-20 pt-32 pb-20'>
          <div>
            <h1 className="mb-12 text-center">{frontmatter.title}</h1>
            <ReactMarkdown source={frontmatter.content} className="regulations"></ReactMarkdown>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TermsPage

export const pageQuery = graphql`
  query TermsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "terms" } }) {
      frontmatter {
        title
        content
        seo {
          title
          description
        }
      }
    }
  }
`